import React from "react";
import { Box, SvgIcon } from "@mui/material";
import { Link } from "react-router-dom";

import BoltPlusLogoMobile from "../../assets/pngs/boltPlusLogo.png";

export const Logo = () => {
  return (
    <Box sx={{ display: "inline-flex" }} component={Link} to="/home">
      {/* <SvgIcon
        component={BoltPlusLogoMobile}
        inheritViewBox
        sx={{
          width: { xs: 134 / 1.5, sm: 134 },
          height: { xs: 55 / 1.5, sm: 55 },
        }}
      /> */}
      <img
        src={BoltPlusLogoMobile}
        alt="BoltPlus Logo"
        style={{
          width: 134,
          height: 55,
        }}
      />
    </Box>
  );
};
