import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import ConnectWalletWrapper from "../../hooks/ConnectWalletWrapper";
import { useQuery } from "../../hooks/useQuery";
import { authSelector } from "../../store/auth/selector";
import { fetchEmailSignUpAction, initAuthAction } from "../../store/auth/actions";
import { FlexCol, FlexRow, LoadingView } from "../../components";
import { SorryModal } from "../../components/modals/SorryModal";
import { enumFromStringValue } from "../../utils/formatter";
import { LoginButtons } from "./LoginButtons";
import { EmailLoginForm } from "./EmailLoginForm";
import { EmailSignupForm } from "./EmailSignupForm";
import { SIGNUP_STEP } from "./authComps";
import { CreateUserDto } from "../../dtos";
import { TOSForm } from "./TOSForm";
import { PolicyForm } from "./PolicyForm";
import { OnboardingCarousel } from "./OnboardingCarousel";
// import { ReactComponent as BoltPlusAuthLogo } from "../../assets/svgs/boltPlusLogo-christmas.svg";
import BoltPlusAuthLogo from "../../assets/pngs/boltPlusLogo.png";
import BackgroundImage from "../../assets/svgs/auth/auth_bg.svg";

export enum ActionType {
  loginAction = "loginAction",
  loginEmail = "loginEmail",
  signupEmail = "signupEmail",
}

interface Props {}

export const AppLogo = (props: any) => {
  // return <BoltPlusAuthLogo width={200} height={(200 * 31) / 120} {...props} />;
  return (
    <img
      src={BoltPlusAuthLogo}
      alt="BoltPlus Logo"
      style={{
        width: 134,
        height: 55,
      }}
    />
  );
};

export const SignUpPage = (props: Props) => {
  const { isLoggedIn, error, loading, user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  let category = ActionType.loginAction;
  if (query.get("category") && typeof query.get("category") === "string") {
    const temp = enumFromStringValue(ActionType, query.get("category")!);
    category = temp === undefined ? ActionType.loginAction : temp;
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [actionType, setActionType] = useState<ActionType>(category);
  const [showModal, setShowModal] = useState(false);
  const [signupStep, setStep] = useState(SIGNUP_STEP.form);
  const [submitData, setSubmitData] = useState<CreateUserDto>();
  const [localLoading, setLocalLoading] = useState(false);

  const isSignupAction = actionType === ActionType.signupEmail;

  // useEffect(() => {
  //   if (isLoggedIn && !error) {
  //     if (query.get("redirect") && typeof query.get("redirect") === "string") {
  //       navigate(query.get("redirect")!);
  //     } else {
  //       navigate("/home");
  //     }
  //   } else if (error && error.message) {
  //     setErrorMessage(error.message);
  //   } else {
  //     setErrorMessage("");
  //   }
  // }, [error, isLoggedIn, navigate]);

  // useEffect(() => {
  //   if (!user || !user?.id) {
  //     dispatch(initAuthAction());
  //   }
  // }, []);

  useEffect(() => {
    if (signupStep !== SIGNUP_STEP.form) {
      setLocalLoading(true);
      setTimeout(() => {
        setLocalLoading(false);
      }, 500);
    }
  }, [signupStep]);

  useEffect(() => {
    setErrorMessage("");
  }, [actionType]);

  const handleAuthAction = () => {
    const newAction = actionType === ActionType.loginAction ? ActionType.signupEmail : ActionType.loginAction;
    setActionType(newAction);
  };

  const setEmailLogin = () => {
    setActionType(ActionType.loginEmail);
  };

  const handleBackAction = () => {
    setActionType(ActionType.loginAction);
    setStep(SIGNUP_STEP.form);
  };

  return (
    <ConnectWalletWrapper isLogin>
      <LoadingView visible={loading} />
      <SorryModal
        open={showModal}
        title="&#128561; Oops! We're still working on this..."
        confirmTitle="Okay"
        description="Thanks for your interest! Not all of our features are ready yet. Please register your interest to use our brand-new version of boltplus.tv !"
        onClose={() => setShowModal(false)}
        onConfirm={() => setShowModal(false)}
      />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundPosition: "bottom right",
          backgroundImage: `url(${BackgroundImage})`,
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sm={6} md={4} sx={{ background: "#171b3acc", backdropFilter: "blur(10px)" }}>
            <LoadingView visible={localLoading} />
            {signupStep === SIGNUP_STEP.form && (
              <FlexCol
                sx={{ alignItems: "center", justifyContent: "center", margin: "auto", width: "80%", height: "100%" }}
              >
                <AppLogo />
                <Typography sx={{ marginTop: "24px", marginBottom: "26px", fontSize: 14 }}>
                  {isSignupAction ? "Create a" : "Login with your"} BOLT+ account
                </Typography>
                {errorMessage && (
                  <Alert variant="outlined" severity="error" sx={{ mt: 2, mb: 1, width: 300 }}>
                    {errorMessage}
                  </Alert>
                )}
                {actionType === ActionType.loginAction && (
                  <LoginButtons setErrorMessage={setErrorMessage} setEmailLogin={setEmailLogin} />
                )}
                {actionType === ActionType.loginEmail && <EmailLoginForm setErrorMessage={setErrorMessage} />}
                {actionType === ActionType.signupEmail && (
                  <EmailSignupForm setErrorMessage={setErrorMessage} setStep={setStep} setSubmitData={setSubmitData} />
                )}
                {actionType !== ActionType.loginEmail && (
                  <FlexRow sx={{ alignItems: "center", marginTop: "44px" }}>
                    <Typography sx={{ fontSize: 12 }} color="primary.light">
                      {isSignupAction ? "Already have" : "Don't you have"} an account?
                    </Typography>
                    <Button
                      size="small"
                      onClick={handleAuthAction}
                      sx={{
                        marginLeft: 1,
                        paddingX: 1.5,
                        fontSize: 12,
                        fontWeight: 600,
                        borderRadius: 22,
                        color: "#2F80ED",
                        border: "1px solid #ffffff0d",
                      }}
                    >
                      {isSignupAction ? "Login here" : "Sign up for BOLT+"}
                    </Button>
                  </FlexRow>
                )}

                {actionType === ActionType.loginEmail && (
                  <Button
                    size="small"
                    onClick={handleBackAction}
                    startIcon={<KeyboardArrowLeftIcon />}
                    sx={{
                      marginTop: 6,
                      paddingX: 2,
                      fontSize: 14,
                      fontWeight: 600,
                      color: "white",
                      borderRadius: 4,
                      borderWidth: 0,
                      background: "#ffffff1a",
                    }}
                  >
                    Go Back
                  </Button>
                )}
              </FlexCol>
            )}

            {signupStep === SIGNUP_STEP.tos && (
              <TOSForm onBack={() => setStep(SIGNUP_STEP.form)} onAgree={() => setStep(SIGNUP_STEP.policy)} />
            )}
            {signupStep === SIGNUP_STEP.policy && (
              <PolicyForm
                onBack={() => setStep(SIGNUP_STEP.tos)}
                onAgree={() => dispatch(fetchEmailSignUpAction(submitData))}
              />
            )}
          </Grid>
          <Grid item sm={6} md={8} sx={{ display: { xs: "none", sm: "flex" } }}>
            <OnboardingCarousel />
          </Grid>
        </Grid>
      </Box>
    </ConnectWalletWrapper>
  );
};
