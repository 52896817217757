import { PropsWithChildren } from "react";
import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { useImmersivePanelLayoutContext } from "./ImmersivePanelLayoutContext";
import { ImmersivePanelDrawer } from "./ImmersivePanelDrawer";
import { Main } from "./Main";
import { DrawerControls } from "./DrawerControls";
import { userSelector } from "../../store/auth/selector";

type ImmersivePanelLayoutUiProps = PropsWithChildren<{}>;

export const ImmersivePanelLayoutUi = ({ children }: ImmersivePanelLayoutUiProps) => {
  const { immersivePanel__hidden } = useImmersivePanelLayoutContext();
  const user = useSelector(userSelector);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Main>{children}</Main>

        {!immersivePanel__hidden && <ImmersivePanelDrawer />}
      </Box>

      {!immersivePanel__hidden && <DrawerControls />}
    </>
  );
};
