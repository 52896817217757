import { useEffect, useState, useCallback } from "react";
import { Fab, Grid, Typography, Box, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import { ReactComponent as StopStreamingIcon } from "../../../assets/svgs/stopStreaming.svg";
import {
  AboutTab,
  EntitySkeleton,
  FlexCol,
  FlexRow,
  ProfileHeader,
  TweetsTab,
  LiveStreamPlayer,
} from "../../../components";
import { ConfirmationModal } from "../../../components/modals";
import { userSelector } from "../../../store/auth/selector";
import { userProfileSelector } from "../../../store/profile/selector";
import { setIsStreamingAction } from "../../../store/profile/actions";
import { socketSelector } from "../../../store/socket/selector";
import { streamDataSelector, streamerRecordsSelector, streamerSelector } from "../../../store/streamer/selector";
import { fetchStreamerRecordAction, getStreamerAction, setStreamDataAction } from "../../../store/streamer/actions";
import { ANALYTICS_TRACK_TYPE, CHANNEL_TYPE, PROFILE_MENU_ITEM, RECEIVER_TYPE } from "../../../types";
import { Popup } from "../../../utils";
import { UserFeedList } from "./UserFeedList";
import { VideosTab } from "./VideosTab";
import { useLayers, useMixer } from "../../../hooks/Stream";
import { useQuery } from "../../../hooks/useQuery";
import { apiClient } from "../../../config";
import { BoltAnalytics, ToastService } from "../../../services";
import { useImmersivePanelLayoutContext } from "../../../layouts";
import { defaultChatContainerId } from "../../../constants";
import { useAnalyticsTracker } from "../../../hooks";

export const UserProfilePage = () => {
  const dispatch = useDispatch();
  const { boltId } = useParams();
  const query = useQuery();
  const selectedRecordId = query.get("rewind");

  const {
    streamer: { data: streamerData },
  } = useSelector(streamerSelector);
  const { liveStream } = useSelector(userProfileSelector);
  const { url, isLive, title } = useSelector(streamDataSelector);
  const { socketConnected, notification } = useSelector(socketSelector);
  const { data: videoData } = useSelector(streamerRecordsSelector);
  const authUser = useSelector(userSelector);
  const isMyProfile = authUser?.username === boltId;

  const [isModalVisible, setModalVisible] = useState(false);
  const [isSideChatBoxOpen, setIsSideChatBoxOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState(PROFILE_MENU_ITEM.posts);

  const { resetLayers } = useLayers([]);
  const { resetMixers } = useMixer([]);

  const getStreamer = useCallback((id: any) => dispatch(getStreamerAction(id)), [dispatch]);
  const loadUserVideos = useCallback((data: any) => dispatch(fetchStreamerRecordAction(data)), [dispatch]);

  const { updateContext: updateImmersivePanelLayout } = useImmersivePanelLayoutContext();

  useAnalyticsTracker({
    entityId: streamerData?.id!,
    type: ANALYTICS_TRACK_TYPE.STREAMER,
    userId: authUser?.id,
    enabled: isLive,
  });

  useEffect(() => {
    if (selectedRecordId) {
      BoltAnalytics.trackViews({ entityId: selectedRecordId, type: ANALYTICS_TRACK_TYPE.REWIND, userId: authUser?.id });
    }
  }, [selectedRecordId]);

  useEffect(() => {
    if (streamerData && streamerData.id) {
      updateImmersivePanelLayout({
        chatProps: {
          containerId: defaultChatContainerId,
          channelId: streamerData.id,
          channelType: CHANNEL_TYPE.STREAM,
          receiverName: boltId || "",
          receiverType: RECEIVER_TYPE.USER,
        },
      });
    }
  }, [streamerData, boltId]);

  useEffect(() => {
    loadUserVideos({ userId: streamerData?.id! });
  }, []);

  useEffect(() => {
    if (selectedRecordId) {
      return;
    }

    if (streamerData?.streamProfile?.isLive) {
      dispatch(
        setStreamDataAction({
          url: streamerData.streamProfile?.url,
          isLive: true,
          isChannel: false,
          boltId,
          title: streamerData.streamProfile?.title,
        })
      );
    } else if (videoData.length > 0) {
      dispatch(
        setStreamDataAction({
          url: videoData[0].url,
          isLive: false,
          boltId,
          title: videoData[0].title,
          isChannel: false,
        })
      );
    } else {
      dispatch(setStreamDataAction({ url: undefined, isLive: false, boltId, title: undefined, isChannel: false }));
    }
  }, [streamerData, selectedRecordId]);

  useEffect(() => {
    if (selectedRecordId) {
      return;
    }

    if (!streamerData?.streamProfile?.isLive) {
      if (videoData.length > 0) {
        if (!url) {
          dispatch(
            setStreamDataAction({
              url: videoData[0].url,
              isLive: false,
              boltId,
              title: videoData[0].title,
              isChannel: false,
            })
          );
        }
      } else {
        dispatch(setStreamDataAction({ url: undefined, isLive: false, boltId, title: undefined, isChannel: false }));
      }
    }
  }, [videoData, selectedRecordId]);

  // Block reload the page if user in live stream on browser
  useEffect(() => {
    if (liveStream.isStreaming) {
      window.onbeforeunload = (e: any) => {
        const dialogText = "Are you sure you want to stop stream?";
        e.returnValue = dialogText;
        return dialogText;
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [liveStream.isStreaming]);

  // Get streamer data if receive notification for selected streamer
  useEffect(() => {
    if (socketConnected && notification?.channelId === streamerData?.id) {
      getStreamer(boltId);
    }
  }, [notification]);

  if (!streamerData) return <EntitySkeleton />;

  const stopStreaming = () => {
    if (liveStream.ivsBroadcastClient) {
      liveStream.ivsBroadcastClient.stopBroadcast();
      dispatch(setIsStreamingAction(false));
    }
    if (liveStream.captureStream) {
      liveStream.captureStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (liveStream.layers) {
      resetLayers(liveStream.layers, liveStream.ivsBroadcastClient!);
    }
    if (liveStream.mixerDevices) {
      resetMixers(liveStream.mixerDevices, liveStream.ivsBroadcastClient!);
    }

    setTimeout(() => setModalVisible(false), 500);
  };

  const handlePopout = () => {
    Popup.handleVideoPopup(boltId!);
  };

  useEffect(() => {
    if (!selectedRecordId) {
      return;
    }

    (async () => {
      try {
        const { data: recordRes } = await apiClient.get(`/streamRecords/${selectedRecordId}`);
        dispatch(
          setStreamDataAction({ url: recordRes.url, isLive: false, title: recordRes.title, boltId, isChannel: false })
        );
      } catch (error) {
        ToastService.showErrorMessage("Rewind not found");
      }
    })();
    setCurrentTab(PROFILE_MENU_ITEM.videos);
  }, [selectedRecordId]);

  return (
    <>
      <FlexRow>
        <FlexCol sx={{ width: "100%", transition: "all 300ms ease" }}>
          <FlexRow
            sx={{
              position: "relative",
              width: "100%",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "flex-start",
              overflow: "hidden",
            }}
          >
            <LiveStreamPlayer
              url={url}
              isLive={isLive}
              title={title}
              isSideChatBoxOpen={isSideChatBoxOpen}
              startDate={moment(streamerData?.streamSession?.createdAt).toDate()}
              handleSideChatBoxClose={() => setIsSideChatBoxOpen(true)}
              handlePopout={handlePopout}
            />
          </FlexRow>

          {liveStream.isStreaming && (
            <FlexRow
              sx={{
                py: 1.5,
                backgroundColor: "#13162C",
                justifyContent: "flex-end",
              }}
            >
              <Fab
                variant="extended"
                sx={{
                  height: 34,
                  mr: { xs: 1, sm: 2 },
                  px: { xs: 1, sm: 2.5 },
                  display: "inline-flex",
                  backgroundColor: "#EB5757",
                  zIndex: 0,
                  ":hover": { backgroundColor: "#EB5757", opacity: 0.85 },
                }}
                onClick={() => setModalVisible(true)}
              >
                <StopStreamingIcon />
                <Typography variant="caption" sx={{ fontSize: 14, fontWeight: 600, ml: 1, color: "white" }}>
                  Stop streaming
                </Typography>
              </Fab>
            </FlexRow>
          )}

          <Box sx={{ m: 0, mb: 4 }} alignItems="center" justifyContent="center">
            <ProfileHeader
              user={streamerData}
              openChatBox={() => {}}
              openGiftBox={() => {}}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isUser
            />
            <Grid item xs={12} sx={{ px: { xs: 2, sm: 3, md: 4, lg: 5 } }}>
              {currentTab === PROFILE_MENU_ITEM.videos && <VideosTab userId={streamerData?.id!} />}

              {currentTab === PROFILE_MENU_ITEM.about && (
                <AboutTab
                  content={streamerData?.profile.bio}
                  twitter={streamerData?.profile.twitterId}
                  instagram={streamerData?.profile.instagramId}
                  website={streamerData?.profile.website}
                  discord={streamerData?.profile.discordId}
                />
              )}

              <Container disableGutters maxWidth="md" sx={{ justifyContent: "center" }}>
                <Grid item container md={12} xl={11}>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    {currentTab === PROFILE_MENU_ITEM.posts && (
                      <UserFeedList hidePostBar={!isMyProfile} userId={streamerData?.id!} />
                    )}

                    {currentTab === PROFILE_MENU_ITEM.tweets && (
                      <TweetsTab id={streamerData?.profile?.twitterId!} isUser />
                    )}
                  </Grid>

                  {(currentTab === PROFILE_MENU_ITEM.posts || currentTab === PROFILE_MENU_ITEM.tweets) && (
                    <Grid
                      item
                      lg={4}
                      sx={{ mt: 4, pl: 2, display: { xs: "none", sm: "none", md: "none", lg: "flex" } }}
                    >
                      <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ width: "100%" }}>
                          <a href="https://pay.binance.com/en/merchant-campaign?id=3" target="_blank" rel="noreferrer">
                            <img
                              style={{ width: "100%", height: "auto" }}
                              src="https://cdn.boltplus.tv/assets/images/Bolt+x+Binance+Banner+Empty.png"
                              alt="Advertisement"
                            />
                          </a>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Container>
            </Grid>
          </Box>
        </FlexCol>
      </FlexRow>

      {isModalVisible && (
        <ConfirmationModal
          modalVisible={isModalVisible}
          title="Are you sure you want to stop streaming?"
          submitFn={stopStreaming}
          cancelFn={() => setModalVisible(false)}
          submitLabel="Stop streaming"
          cancelLabel="Cancel"
        />
      )}
    </>
  );
};
